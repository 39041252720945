import React from 'react'
import Layout from '../components/layout'

const FormSuccess = () => (
  <Layout>
    <h1>Megrendelés rögzítve</h1>
    <p>Köszönjük a megrendelést, hamarasan felvesszük a kapcsolatot önnel</p>
    <a href='/'>Vissza az oldalra</a>
  </Layout>
)

export default FormSuccess
